import {
    DetailsList,
    Dropdown,
    DetailsListLayoutMode,
    IColumn,
    SelectionMode,
    TextField,
    PrimaryButton
} from "@fluentui/react";
import "../../../html-constants/styles/common-styles.scss";
import localeStrings from "../../../../assets/localeStrings/english.json";
import { WikiLink } from "../../../configurations/configuration";
import { IHashDetails } from "../../../lib/interfaces/common-interface";
import SectionTitleHelperText from "../../view/section-title-helper-text/section-title-helper-text";

export default function HashDetailsView(props: IHashDetails): React.ReactElement {

    if (props.maliciousHashes == null)
        return;

    const columns: IColumn[] = [
        { key: "hashValue", name: localeStrings["HashDetails_Table_HashValue"], fieldName: "hashValue", minWidth: 300, isResizable: true },
        { key: "attackVectors", name: localeStrings["HashDetails_Table_AttackVectors"], fieldName: "attackVectors", minWidth: 300, isResizable: true,  },
        { key: "malicious", name: localeStrings["HashDetails_Table_Malicious"], fieldName: "malicious", minWidth: 200, isResizable: true },
        { key: "obfuscated", name: localeStrings["HashDetails_Table_Obfuscated"], fieldName: "obfuscated", minWidth: 200, isResizable: true },
    ];

    let items: FileHash[] = [];

    items = props.maliciousHashes.map((item) => ({
        hashValue: item.hashValue,
        malicious: item.malicious,
        obfuscated: item.obfuscated,
    }));
    

    return (
        <div>
            <SectionTitleHelperText
                title={localeStrings[`HashDetails_SectionTitle`]}
                helperText={localeStrings[`HashDetails_SectionHelpText`]}
                linkText={localeStrings[`LearnMoreText`]}
                link={WikiLink}
            />
            <DetailsList
                items={items}
                columns={columns}
                layoutMode={DetailsListLayoutMode.fixedColumns}
                selectionPreservedOnEmptyClick={true}
                selectionMode={SelectionMode.none}
            />
        </div>
    );
}
