import { CommandButton, DefaultButton, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import { StatusCodes } from "http-status-codes";
import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import localeStrings from "../../../assets/localeStrings/english.json";
import { ExtensionThreatAnalysisLogin } from "../../configurations/configuration";
import { ExtensionThreatSummaryApis } from "../../configurations/url-configs";
import { ProductType, ReportState } from "../../lib/enums/common-enums";
import {
  IDeveloperDetails,
  IExtensionAnalysis,
  IExtensionMetaData,
  ISubmitUserRecord,
  IThreatDetails,
} from "../../lib/interfaces/common-interface";
import { URLCreator } from "../../lib/url-creator/url-creator";
import {
  AreUpdatedRecordsSame,
  ExtractDeveloperDetailsDataFromRecord,
  ExtractExtensionAnalysisDataFromRecord,
  ExtractExtensionMetaDataFromRecord,
  ExtractThreatDetailsDataFromRecord,
  ExtractHashDetailsDataFromRecord,
  FormRecord,
  ShouldEditOnNonEditableField,
  getProduct,
} from "../../lib/utils/util";
import Routes from "../../root/routes";
import GenericErrorScreen from "../error-screens/generic-error-screen";
import { useAxiosGet } from "../hooks/useAxiosGet";
import { ApiCallStatus } from "../hooks/useAxiosPost";
import useCollectFormData from "../hooks/useCollectFormData";
import { useSubmitApi } from "../hooks/useSubmitApi";
import { useSubmitUrlState } from "../hooks/useSubmitUrlState";
import DeveloperDetailsSubmit from "./developer-details/developer-details-submit";
import ExtensionAnalysisSubmit from "./extension-analysis/extension-analysis-submit";
import ExtensionMetadataSubmit from "./extension-metadata/extension-metadata-submit";
import NoMetadataFound from "./no-metadata-found/no-metadata-found";
import "./submit.scss";
import ThreatDetailsSubmit from "./threat-details/threat-details-submit";
import HashDetailsSubmit from "./hash-details/hash-details-submit";

export default function Submit(): React.ReactElement {
  const [isInputValidated, setIsInputValidated] = React.useState<boolean>(false);
  const { isCreate, noMetaDataFound, submissionId } = useSubmitUrlState();
  const history = useHistory();
  const [areUpdatedRecordsSame, setAreUpdatedRecordsSame] = React.useState<boolean>(!isCreate);
  const [report, setReport] = React.useState<Omit<ISubmitUserRecord, "reportState">>(null);
  const [shouldDisablePublish, setShouldDisablePublish] = React.useState<boolean>(false);
  const [shouldDisableSaveAsDraft, setShouldDisableSaveAsDraft] = React.useState<boolean>(false);
  const { GetSubmitData, PostData } = useSubmitApi();
  const { result: postResult, apiCallStatus: postApiCallStatus, error: postError, postFunc } = PostData();
  const productType = getProduct(useLocation().pathname);

  const { result: currentDbRecord, loading, error } = GetSubmitData();

  const extensionMetaData = useCollectFormData<IExtensionMetaData>(
    ExtractExtensionMetaDataFromRecord(currentDbRecord),
  );
  const developerDetailsData = useCollectFormData<IDeveloperDetails>(
    ExtractDeveloperDetailsDataFromRecord(currentDbRecord),
  );
  const threatDetailsData = useCollectFormData<IThreatDetails>(ExtractThreatDetailsDataFromRecord(currentDbRecord));
  const extensionAnalysisData = useCollectFormData<IExtensionAnalysis>(
    ExtractExtensionAnalysisDataFromRecord(currentDbRecord),
    );
  const hashDetailsData = useCollectFormData<IHashDetails>(
    ExtractHashDetailsDataFromRecord(currentDbRecord),
  );

  const { result: allRules, loading: allRulesLoading } = useAxiosGet<string[]>(
    new URLCreator(ExtensionThreatSummaryApis.GetAllRuleIds)
      .GetUrl(),
    ExtensionThreatAnalysisLogin,
  );

  React.useEffect(() => {
    if (currentDbRecord?.reportState === ReportState.Published) {
      setShouldDisablePublish(!isInputValidated || areUpdatedRecordsSame);
    } else {
      setShouldDisablePublish(!isInputValidated);
      setShouldDisableSaveAsDraft(!isInputValidated || areUpdatedRecordsSame);
    }
  }, [areUpdatedRecordsSame, isInputValidated]);

  React.useEffect(() => {
    setIsInputValidated(
      extensionMetaData.isCurrentDataValid &&
      threatDetailsData.isCurrentDataValid &&
      developerDetailsData.isCurrentDataValid &&
      extensionAnalysisData.isCurrentDataValid,
    );
    const newReport = FormRecord(
      extensionMetaData.data,
      developerDetailsData.data,
      threatDetailsData.data,
      extensionAnalysisData.data,
      hashDetailsData.data,
    );
    setAreUpdatedRecordsSame(AreUpdatedRecordsSame(newReport, currentDbRecord));
    if (!isCreate) {
      newReport[`id`] = currentDbRecord?.id;
      newReport[`reviewName`] = currentDbRecord?.reviewName;
    }
      setReport(newReport);
  }, [extensionMetaData.data, threatDetailsData.data, developerDetailsData.data, extensionAnalysisData.data, hashDetailsData.data]);

  if (loading || postApiCallStatus === ApiCallStatus.Loading || allRulesLoading) {
    return <Spinner size={SpinnerSize.medium} />;
  } else if (error || postError) {
    if (error?.response?.status === StatusCodes.NOT_FOUND && isCreate && !!!noMetaDataFound) {
      return <NoMetadataFound submissionId={submissionId} />;
    }
    if (!!!noMetaDataFound || postError) {
      return <GenericErrorScreen error={error || postError} />;
    }
  }

  if (postApiCallStatus === ApiCallStatus.Succeeded) {
    history.push(`/${productType}/${Routes.ThreatAnalysisRecordSearch}/${postResult.id}`);
  }
  return (
    <div>
      <h2 className="view_title">{localeStrings[`Create_Title`]}</h2>
      <hr />
      <div className="action-pane">
        <div className="action-items">
          <CommandButton
            iconProps={{ iconName: "save" }}
            text={localeStrings[isCreate ? `SaveAsDraft` : `UpdateDraft`]}
            disabled={shouldDisableSaveAsDraft}
            onClick={() =>
              postFunc({
                ...report,
                reportState: ReportState.Draft,
              })
            }
          />
          <div className="publish-cancel-buttons">
            <DefaultButton text={localeStrings[`Cancel`]} onClick={() => history.push(`/${ProductType.Extension}/${Routes.Home}`)} />
            <PrimaryButton
              disabled={shouldDisablePublish}
              text={localeStrings[isCreate || currentDbRecord.reportState === ReportState.Draft ? `Publish` : `Update`]}
              onClick={() =>
                postFunc({
                  ...report,
                  reportState: ReportState.Published,
                })
              }
            />
          </div>
        </div>
        <hr />
        <div className="metadata-from">
          <ExtensionMetadataSubmit
            {...extensionMetaData}
            allowEditOnNonEditableField={ShouldEditOnNonEditableField(noMetaDataFound, error)}
          />
          <DeveloperDetailsSubmit
            {...developerDetailsData}
            allowEditOnNonEditableField={ShouldEditOnNonEditableField(noMetaDataFound, error)}
          />
          <ThreatDetailsSubmit {...threatDetailsData} />
          <HashDetailsSubmit {...hashDetailsData} />
          <ExtensionAnalysisSubmit {...extensionAnalysisData} allRules={allRules} />
        </div>
      </div>
    </div>
  );
}
